import React, { useEffect, useState, useCallback, useRef, useContext, createContext } from 'react';
import EventEmitter from 'events';
import { nanoid } from 'nanoid';
import Video from 'twilio-video';
import { useDispatch } from 'react-redux';

import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_AUDIO_INPUT_KEY, SELECTED_AUDIO_OUTPUT_KEY, SELECTED_VIDEO_INPUT_KEY } from '../../constants/visio';
import { VideoContext } from './VisioProvider';
import { NetworkCondition, TEST_DURATION } from './PreflightTest';

import { getPreflightToken, getToken } from '../../api/visio';
import { getAppointment } from '../../actions/appointment';
import { userName } from '../../libs/formatters';

export const useHandleRoomDisconnectionErrors = (room, onError) => {
    useEffect(() => {
        const onDisconnected = (room, error) => {
            if (error) {
                onError(error);
            }
        };

        room.on('disconnected', onDisconnected);
        return () => {
            room.off('disconnected', onDisconnected);
        };
    }, [room, onError]);
}


export const useHandleOnDisconnect = (room, onDisconnect) => {
    useEffect(() => {
        room.on('disconnected', onDisconnect);
        return () => {
            room.off('disconnected', onDisconnect);
        };
    }, [room, onDisconnect]);
}


export const useHandleTrackPublicationFailed = (room, onError) => {
    const { localParticipant } = room;
    useEffect(() => {
        if (localParticipant) {
            localParticipant.on('trackPublicationFailed', onError);
            return () => {
                localParticipant.off('trackPublicationFailed', onError);
            };
        }
    }, [localParticipant, onError]);
}

export const isMobile = (() => {
    if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
        return false;
    }
    return /Mobile/.test(navigator.userAgent);
})();

window.TwilioVideo = Video;
export const useRoom = (localTracks, onError, options) => {
    const [room, setRoom] = useState(new EventEmitter());
    const [isConnecting, setIsConnecting] = useState(false);
    const optionsRef = useRef(options);

    useEffect(() => {
        // This allows the connect function to always access the most recent version of the options object. This allows us to
        // reliably use the connect function at any time.
        optionsRef.current = options;
    }, [options]);

    const connect = useCallback(
        token => {
            setIsConnecting(true);
            return Video.connect(token, { ...optionsRef.current, tracks: localTracks }).then(
                newRoom => {
                    setRoom(newRoom);
                    const disconnect = () => newRoom.disconnect();

                    // This app can add up to 13 'participantDisconnected' listeners to the room object, which can trigger
                    // a warning from the EventEmitter object. Here we increase the max listeners to suppress the warning.
                    newRoom.setMaxListeners(15);

                    newRoom.once('disconnected', () => {
                        // Reset the room only after all other `disconnected` listeners have been called.
                        setTimeout(() => setRoom(new EventEmitter()));
                        window.removeEventListener('beforeunload', disconnect);

                        if (isMobile) {
                            window.removeEventListener('pagehide', disconnect);
                        }
                    });

                    // @ts-ignore
                    window.twilioRoom = newRoom;

                    newRoom.localParticipant.videoTracks.forEach(publication =>
                        // All video tracks are published with 'low' priority because the video track
                        // that is displayed in the 'MainParticipant' component will have it's priority
                        // set to 'high' via track.setPriority()
                        publication.setPriority('low')
                    );

                    setIsConnecting(false);

                    // Add a listener to disconnect from the room when a user closes their browser
                    window.addEventListener('beforeunload', disconnect);

                    if (isMobile) {
                        // Add a listener to disconnect from the room when a mobile user closes their browser
                        window.addEventListener('pagehide', disconnect);
                    }
                },
                error => {
                    onError(error);
                    setIsConnecting(false);
                }
            );
        },
        [localTracks, onError]
    );

    return { room, isConnecting, connect };
}

export const useDevices = () => {
    const [devices, setDevices] = useState([]);

    useEffect(() => {
        const getDevices = () => navigator.mediaDevices.enumerateDevices().then(devices => setDevices(devices));
        navigator.mediaDevices.addEventListener('devicechange', getDevices);
        getDevices();

        return () => {
            navigator.mediaDevices.removeEventListener('devicechange', getDevices);
        };
    }, []);

    return {
        audioInputDevices: devices.filter(device => device.kind === 'audioinput'),
        videoInputDevices: devices.filter(device => device.kind === 'videoinput'),
        audioOutputDevices: devices.filter(device => device.kind === 'audiooutput'),
        hasAudioInputDevices: devices.filter(device => device.kind === 'audioinput').length > 0,
        hasVideoInputDevices: devices.filter(device => device.kind === 'videoinput').length > 0,
    };
}
  
export const useLocalTracks = () => {
    const [audioTrack, setAudioTrack] = useState();
    const [videoTrack, setVideoTrack] = useState();
    const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(false);
    const { audioInputDevices, videoInputDevices, hasAudioInputDevices, hasVideoInputDevices } = useDevices();

    const getLocalAudioTrack = useCallback(deviceId => {
        const options = {};

        if (deviceId) {
            options.deviceId = { exact: deviceId };
        }

        return Video.createLocalAudioTrack(options).then(newTrack => {
            setAudioTrack(newTrack);
            return newTrack;
        });
    }, []);

    const getLocalVideoTrack = useCallback(() => {
        const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);

        const hasSelectedVideoDevice = videoInputDevices.some(
            device => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
        );

        const options = {
            ...(DEFAULT_VIDEO_CONSTRAINTS),
            name: `camera-${Date.now()}`,
            ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId } }),
        };

        return Video.createLocalVideoTrack(options).then(newTrack => {
            setVideoTrack(newTrack);
            return newTrack;
        });
    }, [videoInputDevices]);

    const removeLocalAudioTrack = useCallback(() => {
        if (audioTrack) {
            audioTrack.stop();
            setAudioTrack(undefined);
        }
    }, [audioTrack]);

    const removeLocalVideoTrack = useCallback(() => {
        if (videoTrack) {
            videoTrack.stop();
            setVideoTrack(undefined);
        }
    }, [videoTrack]);

    const getAudioAndVideoTracks = useCallback(() => {
        if (!hasAudioInputDevices && !hasVideoInputDevices) return Promise.resolve();
        if (isAcquiringLocalTracks || audioTrack || videoTrack) return Promise.resolve();

        setIsAcquiringLocalTracks(true);

        const selectedAudioDeviceId = window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY);
        const selectedVideoDeviceId = window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY);

        const hasSelectedAudioDevice = audioInputDevices.some(
            device => selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId
        );
        const hasSelectedVideoDevice = videoInputDevices.some(
            device => selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
        );

        const localTrackConstraints = {
            video: hasVideoInputDevices && {
                ...(DEFAULT_VIDEO_CONSTRAINTS),
                name: `camera-${Date.now()}`,
                ...(hasSelectedVideoDevice && { deviceId: { exact: selectedVideoDeviceId } }),
            },
            audio: hasSelectedAudioDevice ? { deviceId: { exact: selectedAudioDeviceId } } : hasAudioInputDevices,
        };

        return Video.createLocalTracks(localTrackConstraints)
            .then(tracks => {
                const videoTrack = tracks.find(track => track.kind === 'video');
                const audioTrack = tracks.find(track => track.kind === 'audio');
                if (videoTrack) {
                    setVideoTrack(videoTrack);
                }
                if (audioTrack) {
                    setAudioTrack(audioTrack);
                }
            })
            .finally(() => setIsAcquiringLocalTracks(false));
    }, [
        hasAudioInputDevices,
        hasVideoInputDevices,
        audioTrack,
        videoTrack,
        audioInputDevices,
        videoInputDevices,
        isAcquiringLocalTracks,
    ]);

    const localTracks = [audioTrack, videoTrack].filter(track => track !== undefined);

    return {
        localTracks,
        getLocalVideoTrack,
        getLocalAudioTrack,
        isAcquiringLocalTracks,
        removeLocalAudioTrack,
        removeLocalVideoTrack,
        getAudioAndVideoTracks,
    };
}

export const useVideoContext = () => {
    const context = useContext(VideoContext);
    if (!context) {
        throw new Error('useVideoContext must be used within a VideoProvider');
    }
    return context;
}
  

export const useRoomState = () => {
    const { room } = useVideoContext();
    const [state, setState] = useState('disconnected');

    useEffect(() => {
        const setRoomState = () => setState((room.state || 'disconnected'));
        setRoomState();
        room
            .on('disconnected', setRoomState)
            .on('reconnected', setRoomState)
            .on('reconnecting', setRoomState);
        return () => {
            room
                .off('disconnected', setRoomState)
                .off('reconnected', setRoomState)
                .off('reconnecting', setRoomState);
        };
    }, [room]);

    return state;
}


export const usePreflightTest = (publisherToken, subscriberToken) => {
    
    const { isConnecting } = useVideoContext();
    const [testReport, setTestReport] = useState();
    const [testFailure, setTestFailure] = useState();
    const [isTestRunning, setIsTestRunning] = useState(false);
    const preflightTestRef = useRef();

    // This will stop the preflight test when the user connects to a room
    useEffect(() => {
        if (isConnecting) {
            preflightTestRef.current?.stop();
            preflightTestRef.current?.removeAllListeners('completed');
            preflightTestRef.current?.removeAllListeners('failed');
        }
    }, [isConnecting]);

    // This will stop the preflight test when the component is unmounted.
    useEffect(() => {
        return () => {
            preflightTestRef.current?.stop();
            preflightTestRef.current?.removeAllListeners('completed');
            preflightTestRef.current?.removeAllListeners('failed');
        };
    }, []);

    useEffect(() => {
        if (publisherToken && subscriberToken && !testReport && !testFailure && !isTestRunning) {
            setIsTestRunning(true);

            preflightTestRef.current = Video.testPreflight(publisherToken, subscriberToken, { duration: TEST_DURATION });

            preflightTestRef
                .current.on('completed', report => {
                    setTestReport(report);
                    setIsTestRunning(false);
                    console.log('Preflight network test completed. See test report below:');
                    console.log(report);
                })
                .on('failed', error => {
                    setTestFailure(error);
                    setIsTestRunning(false);
                    console.log('Preflight network test failed. See error below:');
                    console.log(error);
                });
        }
    }, [publisherToken, subscriberToken, testReport, testFailure, isTestRunning]);

    return {
        testReport,
        testFailure,
        isTestRunning,
    };
}
  

export const useGetPreflightTokens = () => {
    const [tokens, setTokens] = useState();
    const [tokenError, setTokenError] = useState();
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        if (!isFetching && !tokens) {
            const roomName = 'preflight-network-test-' + nanoid();

            setIsFetching(true);

            const publisherIdentity = 'participant-' + nanoid();
            const subscriberIdentity = 'participant-' + nanoid();

            Promise.all([getPreflightToken(publisherIdentity, roomName), getPreflightToken(subscriberIdentity, roomName)])
                .then(res => {
                    setTokens(res.map(r => r.token));
                    setIsFetching(false);
                })
                .catch(error => setTokenError(error));
        }
    }, [isFetching, tokens]);

    return { tokens, tokenError };
}

const getSingleNetworkCondition = (stat, yellowThreshold, redThreshold) => {
    if (typeof stat === 'undefined') {
        // We ignore values that are missing
        return NetworkCondition.Green;
    }

    if (stat >= redThreshold) return NetworkCondition.Red;
    if (stat >= yellowThreshold) return NetworkCondition.Yellow;
    return NetworkCondition.Green;
}

export const getNetworkCondition = testReport => {
    if (!testReport) return undefined;

    const latency = testReport.stats.rtt?.average;
    const jitter = testReport.stats.jitter.average;
    const packetLoss = testReport.stats.packetLoss.average;

    return Math.min(
        getSingleNetworkCondition(latency, 200, 400),
        getSingleNetworkCondition(jitter, 30, 100),
        getSingleNetworkCondition(packetLoss, 3, 7)
    );
}

export const useGetToken = () => {
    const [isFetching, setIsFetching] = useState(false);

    const _getToken = useCallback(async (planningId, eventId) => {
        setIsFetching(true)
        const res = await getToken(planningId, eventId);
        setIsFetching(false);
        return res.token;
    }, [])


    return [_getToken, isFetching]
    
}

export const useMediaStreamTrack = track => {
    const [mediaStreamTrack, setMediaStreamTrack] = useState(track?.mediaStreamTrack);

    useEffect(() => {
        setMediaStreamTrack(track?.mediaStreamTrack);

        if (track) {
            const handleStarted = () => setMediaStreamTrack(track.mediaStreamTrack);
            track.on('started', handleStarted);
            return () => {
                track.off('started', handleStarted);
            };
        }
    }, [track]);

    return mediaStreamTrack;
}


export const useVideoTrackDimensions = track => {
    const [dimensions, setDimensions] = useState(track?.dimensions);

    useEffect(() => {
        setDimensions(track?.dimensions);

        if (track) {
            const handleDimensionsChanged = track => setDimensions({
                width: track.dimensions.width,
                height: track.dimensions.height
            });
            track.on('dimensionsChanged', handleDimensionsChanged);
            return () => {
                track.off('dimensionsChanged', handleDimensionsChanged);
            };
        }
    }, [track]);

    return dimensions;
}


export const useIsTrackEnabled = track => {
    const [isEnabled, setIsEnabled] = useState(track ? track.isEnabled : false);

    useEffect(() => {
        setIsEnabled(track ? track.isEnabled : false);

        if (track) {
            const setEnabled = () => setIsEnabled(true);
            const setDisabled = () => setIsEnabled(false);
            track.on('enabled', setEnabled);
            track.on('disabled', setDisabled);
            return () => {
                track.off('enabled', setEnabled);
                track.off('disabled', setDisabled);
            };
        }
    }, [track]);

    return isEnabled;
}

export const useLocalAudioToggle = () => {
    const { localTracks } = useVideoContext();
    const audioTrack = localTracks.find(track => track.kind === 'audio');
    const isEnabled = useIsTrackEnabled(audioTrack);

    const toggleAudioEnabled = useCallback(() => {
        if (audioTrack) {
            audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable();
        }
    }, [audioTrack]);

    return [isEnabled, toggleAudioEnabled];
}

export const useLocalVideoToggle = () => {
    const {
        room: { localParticipant },
        localTracks,
        getLocalVideoTrack,
        removeLocalVideoTrack,
        onError,
    } = useVideoContext();
    const videoTrack = localTracks.find(track => track.name.includes('camera'));
    const [isPublishing, setIspublishing] = useState(false);

    const toggleVideoEnabled = useCallback(() => {
        if (!isPublishing) {
            if (videoTrack) {
                const localTrackPublication = localParticipant?.unpublishTrack(videoTrack);
                // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
                localParticipant?.emit('trackUnpublished', localTrackPublication);
                removeLocalVideoTrack();
            } else {
                setIspublishing(true);
                getLocalVideoTrack()
                    .then(track => localParticipant?.publishTrack(track, { priority: 'low' }))
                    .catch(onError)
                    .finally(() => setIspublishing(false));
            }
        }
    }, [videoTrack, localParticipant, getLocalVideoTrack, isPublishing, onError, removeLocalVideoTrack]);

    return [!!videoTrack, toggleVideoEnabled];
}


export const useActiveSinkId = () => {
    const { audioOutputDevices } = useDevices();
    const [activeSinkId, _setActiveSinkId] = useState('default');

    const setActiveSinkId = useCallback(
        sinkId => {
            window.localStorage.setItem(SELECTED_AUDIO_OUTPUT_KEY, sinkId);
            _setActiveSinkId(sinkId);
        },
        [_setActiveSinkId]
    );

    useEffect(() => {
        const selectedSinkId = window.localStorage.getItem(SELECTED_AUDIO_OUTPUT_KEY);
        const hasSelectedAudioOutputDevice = audioOutputDevices.some(
            device => selectedSinkId && device.deviceId === selectedSinkId
        );
        if (hasSelectedAudioOutputDevice) {
            _setActiveSinkId(selectedSinkId);
        }
    }, [audioOutputDevices]);

    return [activeSinkId, setActiveSinkId];
}



export const SelectedParticipantContext = createContext();

export const useSelectedParticipant = () => {
    const [selectedParticipant, setSelectedParticipant] = useContext(SelectedParticipantContext);
    return [selectedParticipant, setSelectedParticipant];
}

export const SelectedParticipantProvider = ({ room, children }) => {
    const [selectedParticipant, _setSelectedParticipant] = useState(null);
    const setSelectedParticipant = participant =>
        _setSelectedParticipant(prevParticipant => (prevParticipant === participant ? null : participant));

    useEffect(() => {
        const onDisconnect = () => _setSelectedParticipant(null);
        const handleParticipantDisconnected = participant =>
            _setSelectedParticipant(prevParticipant => (prevParticipant === participant ? null : prevParticipant));

        room.on('disconnected', onDisconnect);
        room.on('participantDisconnected', handleParticipantDisconnected);
        return () => {
            room.off('disconnected', onDisconnect);
            room.off('participantDisconnected', handleParticipantDisconnected);
        };
    }, [room]);

    return (
        <SelectedParticipantContext.Provider value={[selectedParticipant, setSelectedParticipant]}>
            {children}
        </SelectedParticipantContext.Provider>
    );
}


export const usePublications = participant => {
    const [publications, setPublications] = useState([]);

    useEffect(() => {
        if (!participant) return;
        // Reset the publications when the 'participant' variable changes.
        setPublications(Array.from(participant.tracks.values()));

        const publicationAdded = publication =>
            setPublications(prevPublications => [...prevPublications, publication]);
        const publicationRemoved = publication =>
            setPublications(prevPublications => prevPublications.filter(p => p !== publication));

        participant.on('trackPublished', publicationAdded);
        participant.on('trackUnpublished', publicationRemoved);
        return () => {
            participant.off('trackPublished', publicationAdded);
            participant.off('trackUnpublished', publicationRemoved);
        };
    }, [participant]);

    return publications;
}


export const useIsTrackSwitchedOff = track => {
    const [isSwitchedOff, setIsSwitchedOff] = useState(track && track.isSwitchedOff);

    useEffect(() => {
        // Reset the value if the 'track' variable changes
        setIsSwitchedOff(track && track.isSwitchedOff);

        if (track) {
            const handleSwitchedOff = () => setIsSwitchedOff(true);
            const handleSwitchedOn = () => setIsSwitchedOff(false);
            track.on('switchedOff', handleSwitchedOff);
            track.on('switchedOn', handleSwitchedOn);
            return () => {
                track.off('switchedOff', handleSwitchedOff);
                track.off('switchedOn', handleSwitchedOn);
            };
        }
    }, [track]);

    return !!isSwitchedOff;
}

export const useTrack = publication => {
    const [track, setTrack] = useState(publication && publication.track);

    useEffect(() => {
        // Reset the track when the 'publication' variable changes.
        setTrack(publication && publication.track);

        if (publication) {
            const removeTrack = () => setTrack(null);

            publication.on('subscribed', setTrack);
            publication.on('unsubscribed', removeTrack);
            return () => {
                publication.off('subscribed', setTrack);
                publication.off('unsubscribed', removeTrack);
            };
        }
    }, [publication]);

    return track;
}


export const useParticipantIsReconnecting = participant => {
    const [isReconnecting, setIsReconnecting] = useState(false);

    useEffect(() => {
        if (!participant) return;
        const handleReconnecting = () => setIsReconnecting(true);
        const handleReconnected = () => setIsReconnecting(false);

        handleReconnected(); // Reset state when there is a new participant

        participant.on('reconnecting', handleReconnecting);
        participant.on('reconnected', handleReconnected);
        return () => {
            participant.off('reconnecting', handleReconnecting);
            participant.off('reconnected', handleReconnected);
        };
    }, [participant]);

    return isReconnecting;
}


export const useParticipants = () => {
    const { room } = useVideoContext();
    const [participants, setParticipants] = useState(Array.from(room.participants.values()));

    useEffect(() => {
        const participantConnected = participant =>
            setParticipants(prevParticipants => [...prevParticipants, participant]);
        const participantDisconnected = participant =>
            setParticipants(prevParticipants => prevParticipants.filter(p => p !== participant));
        room.on('participantConnected', participantConnected);
        room.on('participantDisconnected', participantDisconnected);
        return () => {
            room.off('participantConnected', participantConnected);
            room.off('participantDisconnected', participantDisconnected);
        };
    }, [room]);

    return participants;
}

export const useOnlyMeInRoom = () => {
    const participants = useParticipants();
    return (participants || []).length === 0;
}

export const useVisioEventPatient = () => {

    const [event, setEvent] = useState();
    const { planningId, eventId } = useVideoContext();
    const dispatch = useDispatch();

    useEffect(() => {

        if (!planningId ||!eventId) return;

        const _getEvent = async () => {
            const e = await dispatch(getAppointment({
                practitionerId: planningId,
                id: eventId
            }));
            setEvent(e);
        }
        _getEvent();

    }, [planningId, eventId, dispatch])

    return event?.with ? userName(event.with) : '';

}

export const useParticipantNetworkQualityLevel = participant => {
    const [networkQualityLevel, setNetworkQualityLevel] = useState(participant?.networkQualityLevel);

    useEffect(() => {
        if (!participant) return;
        const handleNewtorkQualityLevelChange = newNetworkQualityLevel =>
            setNetworkQualityLevel(newNetworkQualityLevel);

        setNetworkQualityLevel(participant?.networkQualityLevel);
        participant.on('networkQualityLevelChanged', handleNewtorkQualityLevelChange);
        return () => {
            participant.off('networkQualityLevelChanged', handleNewtorkQualityLevelChange);
        };
    }, [participant]);

    return networkQualityLevel;
}

export const useHandleForceDisconnect = (room, removeLocalAudioTrack, removeLocalVideoTrack) => {

    useEffect(() => {

        const _forceCloseVisio = () => {
            console.log("_forceCloseVisio")
            removeLocalAudioTrack();
            removeLocalVideoTrack();
            if (room.state === "connected") room.disconnect();
        }

        window.addEventListener("sprel::visio::force-close", _forceCloseVisio);

        return () => window.removeEventListener("sprel::visio::force-close", _forceCloseVisio);

    }, [room, removeLocalAudioTrack, removeLocalVideoTrack])
}