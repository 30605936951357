import React, { memo, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { Button, Backdrop, LinearProgress } from '@material-ui/core';
import { useStripe } from '@stripe/react-stripe-js';

import { uploadLegalsDocuments, updateLegalsDocuments } from '../../actions/planning';
import { useSelectedPlanningId } from '../../hooks/planning';
import { useCoolState } from '../../hooks/react';
import { useLexique } from '../../locales';

import Alert from '../ui/Alert';
import Markdown from '../ui/Markdown';

const ACCEPT_IMAGES = ['image/jpeg', 'image/png'];
const ACCEPT_IMAGES_PDF = ['application/pdf', 'image/jpeg', 'image/png'];

const PaymentsUploadDocuments = () => {

    const lexique = useLexique("payments.documents");
    const stripe = useStripe();

    const [documents, setDocument] = useCoolState({
        frontId: null,
        backId: null,
        frontAdd: null,
    });
    const [loading, setLoading] = useState();
    const dispatch = useDispatch();
    const planningId = useSelectedPlanningId();

    const onFile = useCallback((id, file) => setDocument({
        [id]: file
    }), [setDocument]);

    const onSubmitDocuments = useCallback(async () => {

        if (!documents.frontId || !documents.backId || !documents.frontAdd) return;

        setLoading(true);
        const tokens = await dispatch(uploadLegalsDocuments({
            ...documents,
            planningId
        }));

        if (tokens.error) {
            window.alert('An error occured while uploading your files');
            setLoading(false);
            return;
        }

        const personResult = await stripe.createToken('person', {
            verification: {
                document: {
                    front: tokens.frontIdToken,
                    back: tokens.backIdToken
                },
                additional_document: {
                    front: tokens.frontAddToken
                }
            }
        });

        if (!personResult.token) {
            console.log(personResult)
            window.alert('An error occured while processing your files');
            setLoading(false);
            return
        }

        await dispatch(updateLegalsDocuments({
            token: personResult.token,
            planningId
        }));

    }, [documents, dispatch, planningId, stripe]);

    return (
        <div className="payments_documents">
            <Alert severity="info" title={lexique.title} content={lexique.body} />
            <div className="payments_documents_zones">
                <UploadDocument
                    id="frontId"
                    accept={ACCEPT_IMAGES}    
                    onFile={onFile}
                >
                    {!documents.frontId ? (
                        <Markdown>
                            {lexique.frontId}
                        </Markdown>
                    ) : documents.frontId.name }
                </UploadDocument>
                <UploadDocument
                    id="backId"
                    accept={ACCEPT_IMAGES}    
                    onFile={onFile}
                >
                    {!documents.backId ? (
                        <Markdown>
                            {lexique.backId}
                        </Markdown>
                    ) : documents.backId.name }
                </UploadDocument>
                <UploadDocument
                    id="frontAdd"
                    accept={ACCEPT_IMAGES_PDF}    
                    onFile={onFile}
                >
                    {!documents.frontAdd ? (
                        <Markdown>
                            {lexique.frontAdd}
                        </Markdown>
                    ) : documents.frontAdd.name }
                </UploadDocument>
            </div>
            <div className="payments_documents_submit">
                <Button
                    color="primary"
                    onClick={onSubmitDocuments}
                    variant="contained"
                    disableElevation
                    disabled={!documents.frontId || !documents.backId || !documents.frontAdd}
                >
                    {lexique.submit}
                </Button>
            </div>
            {loading && <Backdrop open className="payment_backdrop-loader">
                    <LinearProgress color="secondary" />
            </Backdrop>}
        </div>
    )

}


const UploadDocument = memo(({id, accept, onFile, children}) => {

    const lexique = useLexique("payments.documents");

    const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {

        if (rejectedFiles.length > 0) {
            window.alert(lexique.errorFile);
            return;
        }

        const getFileData = file => new Promise(resolve => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64 = reader.result;
                const formattedBase64 = base64.replace(`data:${file.type};base64,`, '');
                resolve({
                    type: file.type,
                    name: file.name,
                    content: formattedBase64
                })
            };
            reader.readAsDataURL(file);
        });

        const file = await getFileData(acceptedFiles[0]);
        onFile(id, file);

    }, [id, onFile, lexique]);

    const {getRootProps, getInputProps, isDragAccept, isDragReject} = useDropzone({
        onDrop,
        accept,
        multiple: false,
    });

    return (
        <div {...getRootProps()} className={cn("no-outline", "payments_document", isDragAccept && "payments_document--accept", isDragReject && "payments_document--reject")}>
            <input {...getInputProps()} />
               {children}
        </div>
    )

})

export default memo(PaymentsUploadDocuments);