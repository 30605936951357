import React, { memo } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import EmptySlot from './EmptySlot';

import PublicHoliday from '../../libs/public-holiday-france';
import { getUserRightsOnPlanning } from '../../libs/checkers';

const DaySlots = memo(({date, start, steps, step, onSelectSlot, planningConf, currentPlanning, user, selectedPlanning}) => {

    if (!currentPlanning) return null;

    const s = moment(start, "HHmm");

    /* Check closed by params */
    const day = currentPlanning.days[moment(date).format('YYYY-MM-DD')];
    const isClosedByParam = day && day.params && day.params.isClosed;

    /* Check closed by Holiday */
    const holidayKey = PublicHoliday.getFerie(moment(date).startOf('day'));
    const isHolidayClosed = holidayKey !== null && planningConf[`publicHoliday${holidayKey}`] === true;

    const rights = getUserRightsOnPlanning(user, selectedPlanning);

    return Array(steps).fill().map((_,i) => {
                const d = moment(s).add(i*step, "minutes").format("HHmm");
                return <EmptySlot
                    key={d}
                    slot={d}
                    date={date}
                    onSelectSlot={onSelectSlot}
                    isClosed={isHolidayClosed || isClosedByParam}
                    hasRight={rights.addAppointment}
                />
    })
})

const mapStateToProps = ({plannings, user}) => ({
	start: plannings && plannings.selected && plannings[plannings.selected] && plannings[plannings.selected].configuration ? plannings[plannings.selected].configuration.start || '0900' : '0900',
    step: plannings && plannings.selected && plannings[plannings.selected] && plannings[plannings.selected].configuration ? plannings[plannings.selected].configuration.step || 15 : 15,
    planningConf: plannings[plannings.selected] ? plannings[plannings.selected].configuration : null,
    currentPlanning: plannings[plannings.selected],
    selectedPlanning: plannings.selected,
    user,
});

export default connect(mapStateToProps)(DaySlots);
