import React, { memo, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useVideoContext } from './hooks';

import DeviceSelectionScreen from './DeviceSelectionScreen';

import MediaErrorSnackbar from './ui/MediaErrorSnackBar';

const useStyle = makeStyles(() => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        position: 'relative',
    }
}));

const PrejoinScreen = () => {

    const { getAudioAndVideoTracks, planningId, eventId } = useVideoContext();
    const [mediaError, setMediaError] = useState();

    const classes = useStyle();

    useEffect(() => {
        if (!mediaError) {
            getAudioAndVideoTracks().catch(error => {
                console.log('Error acquiring local media:');
                console.dir(error);
                setMediaError(error);
            });
        }
    }, [getAudioAndVideoTracks, mediaError]);

    return (
        <div className={classes.container}>
            <DeviceSelectionScreen planningId={planningId} eventId={eventId} />
            <MediaErrorSnackbar error={mediaError} />
        </div>
    );
}

export default memo(PrejoinScreen);