import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import { Scrollbars } from 'react-custom-scrollbars';

import DaySlots from './DaySlots';
import DayEvents from './DayEvents';

import { setCalendarColumnWidth } from '../../actions/ui';
import { getEventTop, getSlotHeight } from '../../libs/getters';

class AgendaContent extends PureComponent {

    componentDidMount() {
        window.addEventListener('resize', this.setCalendarColumnWidth);
        this.setCalendarColumnWidth();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setCalendarColumnWidth);
    }

    setCalendarColumnWidth = () => {
        const { calendarColumnWidth, actions } = this.props;
        const selector = document.getElementById('agenda--column--0');
        if (!selector) {
            setTimeout(setCalendarColumnWidth, 100);
            return null;
        }
        const { width } = selector.getBoundingClientRect();
        if (calendarColumnWidth !== width) actions.setCalendarColumnWidth(width);
    }

    renderGutter = () => {

        const { step, slotSize } = this.props;

         return Array(this.steps).fill().map((_,i) => {
             if (i === 0) return null;

             const start = moment(this.s).add(i*step, "minutes");
             const top = getEventTop({
                 start: this.s,
                 time: start,
                 step,
                 slotSize
             })

             return (
                 <span key={i} style={{top: `${top}px`}} className="column--step gutter--step">{start.format('HH:mm')}</span>
             )
         })
    }

    render() {
        const { start, end, step, days, slotSize, onSelectSlot, onSelectEvent, onMoveEvent, selectedPlanning } = this.props;

        this.s = moment(start, "HHmm");
        this.e = moment(end, "HHmm");

        this.diff = this.e.diff(this.s, 'minutes');

        this.steps = Math.ceil(this.diff/step);

        const slotHeight = getSlotHeight(slotSize);

         return <div className="agenda">
             <Scrollbars
                 className="agenda--wrapper"
                 style={{
                     height: `calc(100vh - 64px - 57px - 90px)`,
                     width: '100%',
                 }}
                 autoHide
             >
                <div className="agenda--content"
                    style={{
                        height: `${this.steps*slotHeight}px`,
                    }}
                >
                    <div className="agenda--column agenda--gutter">
                        {this.renderGutter()}
                    </div>
                    {days.map((d,i) => <div key={`${selectedPlanning}-${i}`} className="agenda--column" id={`agenda--column--${i}`}>
                        <DaySlots
                            date={d}
                            steps={this.steps}
                            start={start}
                            end={end}
                            onSelectSlot={onSelectSlot}
                        />
                        <DayEvents
                            days={days}
                            date={d}
                            onSelectEvent={onSelectEvent}
                            onMoveEvent={onMoveEvent}
                        />
                    </div>)}
                </div>
            </Scrollbars>
         </div>
    }
}

const mapStateToProps = ({plannings, ui}) => ({
	start: plannings && plannings.selected && plannings[plannings.selected] && plannings[plannings.selected].configuration ? plannings[plannings.selected].configuration.start || '0900' : '0900',
	end: plannings && plannings.selected && plannings[plannings.selected] && plannings[plannings.selected].configuration ? plannings[plannings.selected].configuration.end || '2100' : '2100',
    step: plannings && plannings.selected && plannings[plannings.selected] && plannings[plannings.selected].configuration ? plannings[plannings.selected].configuration.step || 15 : 15,
    slotSize: plannings && plannings.selected && plannings[plannings.selected] && plannings[plannings.selected].configuration ? plannings[plannings.selected].configuration.slotSize : "normal",
    calendarColumnWidth: ui.calendarColumnWidth,
    selectedPlanning: plannings.selected,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ setCalendarColumnWidth }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AgendaContent);
